.header {
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(64,152,185,1) 0%, var(--dark-blue) 100%);
    color: #FFF;
}

.hero {
    text-align: left;
    padding-bottom: 0;
}

.hero * {
    margin-bottom: 2em;
}

.hero button {
    margin: 1em 0;
}

.header h2 {
    margin-bottom: 1em;
    margin-top: 0.5em;
}

.rose {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.header h1 {
    font-size: 3em;
    margin: 0.5em 0;
}

.benefits {
    padding-top: 0;
    text-align: center;
}

.benefits .description {
    background: #fff;
    padding: 1em;
    flex-grow: 1;
}

.benefits .description h3 {
    margin-top: 0;
    margin-bottom: 1rem;
}

.benefits img {
    padding: 3em 0;
    height: 6rem;
    margin: 0 auto;
}
  
.benefits p {
    margin: 0; 
    font-size: 1.2rem;
    text-wrap: balance;
}

@media screen and (min-width: 766px) {
    .hero .logo {
        margin-top: 2em;
    }
}

@media screen and (max-width: 765px) {
    .rose {
        height: 100vw;
        margin: 0;
    }
  }

  