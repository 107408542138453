.features {
    background: linear-gradient(180deg, var(--blue), var(--light-blue));
    
}

.feature-cards {
    background-image: url(../images/ROSE-bg-logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.features h2 {
    text-align: center;
    color: #fff;
}

.features h3 {
    color: var(--blue);
    margin: 1em 0;
}

.features h3 span {
    display: block;
    /* margin-bottom: 0.5em; */
    font-size: 1.3em;
}

.features p {
    color: var(--dark-blue);
    margin: 0;
}

.video-wrapper { 
    /* display: flex; */
    height: 100%;
    /* justify-content: center; */
    /* align-items: center; */
    margin-bottom: 3em;
}

@media screen and (max-width: 960px) {
    .features p {
        margin-bottom: 2em;
    }
}