.why-choose img {
    margin: 0 auto;
    display: block;
    padding: 2em;
    height: 85px;
}

.why-choose h2 {
    margin-bottom: 0;
}

.why-choose h3 {
    text-align: center;
    background: var(--blue);
    color: #fff;
    padding: 1.3rem 0.7rem;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.why-choose p:not(.subtitle) {
    padding: 1em 1.5em;
    color: var(--dark-blue);
}

.why-choose .subtitle {
    margin: 1.5em auto;
}

@media screen and (min-width: 901px) {
    .why-choose .subtitle {
        max-width: 85%;
    }
}