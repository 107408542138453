@font-face{font-family:'Grotesque';
          src:url('./assets/fonts/basis-grotesque-regular.woff2') format('woff2'), local('Grotesque');}
@font-face{font-family:'Athletics';src:url('./assets/fonts/Athletics-Regular.woff2') format('woff2'), local('Athletics');font-weight:400;font-display:swap}
@font-face{font-family:'Athletics';src:url('./assets/fonts/Athletics-Bold.woff2') format('woff2'), local('Athletics');font-weight:700;font-style:normal;}
@font-face{font-family:'Athletics';src:url('./assets/fonts/Athletics-Light.woff2') format('woff2'), local('Athletics');font-weight:300;font-style:normal;font-display:swap}

html {
  --blue: #3F98B9;
  --light-blue: #DFF0FA;
  --dark-blue: #106E91;
  --grey: #9CA5BA;
}

body {
  margin: 0;
  font-family: 'Grotesque', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, button {
  font-family: 'Athletics', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  margin-top: 0;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.2rem;
}

section {
  padding: 4em;
}

button {
  background: #fff;
  color: var(--blue);
  border: none;
  padding: 1rem 3rem;
  font-size: 1.9rem;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 600;
}

button:hover {
  /* background-color: #f0f0f0; */
  opacity: 0.8;
}

.reverse-color {
  background: var(--blue);
  color: #fff;
}

.subtitle {
  font-size: 1.3em;
}

.slider {
  overflow: visible !important;
}

.slider > div:first-child {
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  section, footer {
    padding: 3em 2.5em !important;
  }

  h2 {
    font-size: 2.5rem;
  }
}