footer {
    padding: 3em 4em 1em;
}

footer a{
    color: #fff;
}

footer .logo {
    width: 8em;
}

.social-links a {
    margin-right: 1em;
}

footer a:hover {
    color: #000;
}

footer h4:not(.social-title) {
    margin: 0;
}

.copyright {
    text-align: center;
}

@media screen and (max-width: 600px) {
    .copyright {
       margin-top: 3em;
    }
  }