.how-it-works ol {
    counter-reset: list-counter;
    list-style: none;
    padding: 0;
  }
  
.how-it-works li {
    counter-increment: list-counter;
    margin-left: 0.5em;    
    margin-bottom: 2.5em;
    font-size: 1.5rem;
    display: flex;
}
  
.how-it-works li::before {
    content: "0" counter(list-counter);
    font-weight: bold; /* Adjust styling as needed */
    margin-right: 1.5em;
}
  
.dashboardSlide img {
    width: 100%;
    /* max-width: fit-content; */
}

.dashboardSlide .css-bbreus-MuiButtonBase-root-MuiIconButton-root {
   /* background-color: #fff;
    border: 1px solid var(--blue); */
    color: var(--light-blue);
}

.dashboardSlide .css-8ucetn-MuiButtonBase-root-MuiIconButton-root {
    color: var(--blue);
}