label {
    margin-bottom: 0.5em;
    color: var(--dark-blue);
}

form .MuiGrid-item {
    padding: 0 1em 2em;
    display: flex;
}

form button.reverse-color {
    font-size: 1.2rem;
    width: fit-content;
    margin: 0 auto;
}

.loader {
    margin: 0 auto;
}

form h3 {
    text-align: center;
    color: var(--blue);
    font-size: 2.5rem;
}

form .MuiSelect-icon {
    color: var(--blue);
}